import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import WhatsappChat from "../global/botonWhatsapp/BotonWhatsapp";
import { FaCheck, FaCreditCard, FaMoneyBillWave } from "react-icons/fa";
import { FiPhoneCall, FiMapPin, FiClock } from "react-icons/fi";
import { GoCalendar } from "react-icons/go";
import { ButtonContent } from "../global/boton/ButtonContent";
import { BiChevronRightCircle } from "react-icons/bi";

const menu = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Gallery",
    link: "/gallery",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

function Footer() {
  const { rpdata } = useContext(GlobalDataContext);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="flex w-full">
        <div className="flex w-4/5 mx-auto justify-center text-center border-black border-[2px] p-5 rounded-[120px] md:-mb-24 md:-mt-24 -mb-32 -mt-28 bg-white">
          <div className="w-[100%] md:w-[20%] md:flex mx-auto my-auto justify-cente">
            <div className="self-center pt-4 pb-4">
              <img
                src={rpdata?.dbPrincipal?.logo}
                className="w-[85%] rounded-lg"
                alt="Not Found"
              />
            </div>
          </div>
          <div className="w-[60%] hidden md:flex mx-auto my-auto justify-center p-1">
            <h2 className="separator-about">{rpdata?.dbSlogan?.[4].slogan}</h2>
          </div>
          <div className="w-[20%] hidden md:flex mx-auto my-auto justify-center">
            <ButtonContent />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-gradient-to-b to-red-900 from-red-700 bg-opacity-5 pt-28 pb-14 md:pl-24 pl-6  text-white">
        <div className="md:max-w-screen-xl w-[80%]">
          <div className="block md:grid  md:gap-6 lg:gap-8 lg:grid-flow-col">
            <div className="pt-10">
              <h5>About</h5>
              <p className="pb-4">
                {rpdata?.dbAbout?.[1].text.substring(0, 500)}
              </p>
            </div>
            <div className="pt-10">
              <h5>Services</h5>
              <ul className="list-inner-section text-start">
                {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                  return (
                    <Link to="/services" key={index}>
                      <li key={index} className="py-1 px-2 flex items-center">
                        <BiChevronRightCircle className="h-[20px] w-[20px] text-center" />
                        <span className="px-2 text-center">{item.name}</span>
                      </li>
                    </Link>
                  );
                })}
              </ul>
            </div>
            <div className="pt-10">
              <h5>Navigation</h5>
              <ul className="pl-2">
                {menu.map((item, index) => {
                  return (
                    <li className="py-2" onClick={goToTop} key={index}>
                      <Link to={item.link} className="flex">
                        <FaCheck className="self-center" />
                        <span className="pl-2">{item.name}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="pt-10">
              <h5>Contact</h5>
              <ul className="pl-2">
                {rpdata?.dbPrincipal?.location
                  .slice(0, 1)
                  .map((item, index) => {
                    return (
                      <Link to="/contact" key={index}>
                        <li className="py-2 flex items-center">
                          <FiMapPin fontSize={25} />
                          <span className="pl-3">{item.address}</span>
                        </li>
                      </Link>
                    );
                  })}
                {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                  return (
                    <a href={`tel:+1${item.phone}`} key={index}>
                      <li className="py-2 flex items-center">
                        <FiPhoneCall fontSize={25} />
                        <span className="pl-3">{item.phone}</span>
                      </li>
                    </a>
                  );
                })}
                {rpdata?.dbPrincipal?.workdays.map((item, index) => {
                  return (
                    <li className="py-2 flex items-center" key={index}>
                      <GoCalendar fontSize={25} />
                      <span className="pl-3">{item.day}</span>
                    </li>
                  );
                })}
                {rpdata?.dbPrincipal?.workHours.map((item, index) => {
                  return (
                    <li className="py-2 flex items-center" key={index}>
                      <FiClock fontSize={25} />
                      <span className="pl-3">{item.hour}</span>
                    </li>
                  );
                })}
                {rpdata?.dbPrincipal?.paymentMethod ===
                "we accept cash & checks" ? (
                  <li className="py-2 flex items-center">
                    <FaMoneyBillWave fontSize={25} />
                    <span className="pl-3 capitalize">
                      {rpdata?.dbPrincipal?.paymentMethod}
                    </span>
                  </li>
                ) : (
                  <li className="py-2 flex flex-col">
                    <span className="flex items-center">
                      <FaCreditCard fontSize={25} />
                      <span className="pl-3 capitalize">
                        {rpdata?.dbPrincipal?.paymentMethod}
                      </span>
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex place-content-center md:fixed md:block md:bottom-[140px] md:left-[20px]">
          <img
            src={rpdata?.qrImg}
            alt="no fount"
            className="rounded-lg bg-white h-20 w-20 p-1 md:w-[60%] md:h-[40%] shadow-lg"
          />
        </div>
      </div>
      <WhatsappChat />
      <div className="w-full flex justify-around md:flex-row items-center flex-col-reverse text-center text-white  py-4 bg-red-900">
        <p>
          ©2022, Copy Right By {rpdata?.dbPrincipal?.name}. All Rights Reserved
        </p>
        <ul className="flex">
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <li key={index} className="mr-7">
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <i className={`fab fa-${item.icon}`} aria-hidden="true" />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Footer;
